<template>
  <section class="">
    <div class="row mt-4">
      <div class="col-md-8 offset-md-2">
        <div class="text-right">
          <router-link
            :to="{ name: 'peno.previous_projects' }"
            class="btn btn-action py-1 px-4 mr-3"
          >
            ความคืบหน้าโครงการปีก่อน
          </router-link>
          <router-link
            :to="{ name: 'peno.plan_relation' }"
            class="btn btn-action py-1 px-4"
          >
            จัดทำแผน ฯ
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-8 offset-md-2">
        <div class="box">
          <div class="box-body">
            <div class="text-center mt-4">
              <h2>ประกาศ</h2>
              <h4 class="text-gray">(จาก กยผ)</h4>
            </div>
            <div class="m-4" v-html="latestNationalPlan.announcement"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'peno.announcement',
  computed: {
    ...mapGetters(['latestNationalPlan']),
  },
};
</script>

<style scoped></style>
